
import { ADD_ANNOTATION } from "../graphql/annotation-queries";
import { UPDATE_SCAN_HEADER } from "../graphql/scan-queries";
import { Scan, ScanImageSet } from "../models/models";
import ProcessPDAL from "./process-pdal.vue";
import { mapGetters } from "vuex";
import PublishScan from "./publish-scan.vue";

// eslint-disable-next-line
declare const Potree: any;
// eslint-disable-next-line
declare const THREE: any;

declare global {
  interface Window {
    // eslint-disable-next-line
    viewer: any;
  }
}

function getCenterOfBoundingBox(box) {
  const midx = box.min.x + (box.max.x - box.min.x) / 2;
  const midy = box.min.y + (box.max.y - box.min.y) / 2;
  const z = box.max.z;
  return {
    x: midx,
    y: midy,
    z: z,
  };
}

//
// the following PCDB classes are helper classes to keep the scans, and the version of scans, and the clouds, organized.
//
class PCDBVersion {
  private db: PCDBScene;
  private scan: PCDBScan = null;
  id: number = null;
  name: string = null;
  url: string = null;
  cloud: any = null;
  bbox = null;
  centerPoint = null;
  loaded = false;

  constructor(db: PCDBScene, scan: PCDBScan, id, name, url) {
    this.db = db;
    this.scan = scan;
    this.id = id;
    this.name = name;
    this.url = url;
  }

  private load(zoom = false) {
    // console.log(`>>>> loading artifact ${this.name} for ${this.scan.id}`)
    Potree.loadPointCloud(this.url, `${this.scan.id}-${this.name}`, (e) => {
      this.cloud = e.pointcloud;
      this.cloud.visible = true;
      this.loaded = true;

      this.bbox = this.db.vuepage.viewer.getBoundingBox([this.cloud]);
      this.centerPoint = getCenterOfBoundingBox(this.bbox);

      const material = this.cloud.material;
      material.pointSizeType = Potree.PointSizeType.ADAPTIVE; //FIXED
      material.activeAttributeName = this.db.attribute;
      material.intensityRange = [1, 500];
      material.shape = Potree.PointShape.CIRCLE; //Potree.PointShape.SQUARE
      material.gradient = this.scan.gradient;
      material.size = this.db.pointsize;

      this.db.vuepage.viewer.scene.addPointCloud(this.cloud);

      // on load, if the scan is the focused scan id, then ensure the db has it selected as well
      //if(this.db.vuepage.focusedScanId === this.scan.id)
      //    this.db.select(this.scan.id)

      if (zoom) {
        // console.log(`just loaded version ${this.name} for scan ${this.scan.id}, zooming to it.`)
        this.db.zoomScan(this.scan.id);
      }
    });
  }

  show(zoom = false) {
    if (this.cloud === null) this.load(zoom);
    else this.cloud.visible = true;
  }

  hide() {
    if (this.cloud) this.cloud.visible = false;
  }
}

class PCDBScan {
  private db: PCDBScene;
  private scanSrc: Scan;
  private selectedVersion: PCDBVersion;
  private _gradient = null;
  id: string;

  versions: PCDBVersion[] = [];
  labelAnnotation: any = null;
  cameraLabelAnnotation: any = null;
  trajectoryPath: any = null;
  trajectoryLineGeometry: any = null;

  constructor(db: PCDBScene, scanSrc: Scan) {
    this.id = scanSrc.id;
    this.scanSrc = scanSrc;
    this.db = db;

    // preload all the artifacts entries as versions
    for (const artifact of this.scanSrc.artifacts.filter(
      (x) => x.artifactType === "pointcloud"
    )) {
      const version = new PCDBVersion(
        db,
        this,
        artifact.id,
        artifact.name,
        artifact.url
      );
      this.versions.push(version);
    }
    // set the selected version
    // at this point, just picking the earliest one shared, but..
    // TODO: select the unclassified version
    if (this.scanSrc.defaultVersion) {
      this.selectedVersion = this.versions.find(
        (x) => x.name === this.scanSrc.defaultVersion
      );
    } else {
      this.selectedVersion = this.versions.sort((a, b) => a.id - b.id)[0];
    }

    // create the imagePathLine
    const path = [];
    let count = 1;
    for (const imageSet of this.scanSrc.images) {
      if (count % 10 === 0 || count === this.scanSrc.images.length) {
        // console.log(`pushing ${imageSet.x} ${imageSet.y} ${imageSet.z}`)
        path.push(imageSet.X);
        path.push(imageSet.Y);
        path.push(imageSet.Z);
      }
      count++;
    }

    if (path.length > 0)
      this.trajectoryLineGeometry = new THREE.LineGeometry().setPositions(path);
  }

  set pointsize(value: number) {
    for (const version of this.versions) {
      if (version.cloud) version.cloud.material.size = value;
    }
  }

  set attribute(value: string) {
    for (const version of this.versions) {
      if (version.cloud) version.cloud.material.activeAttributeName = value;
    }
  }

  get attribute(): string {
    return this.selectedVersion?.cloud?.material?.activeAttributeName;
  }

  get gradient() {
    if (this._gradient) return this._gradient;
    else return this.db.gradient;
  }

  set gradient(value: any) {
    // console.log(`>> overriding gradient for ${this.id} to ${value}}`)
    this._gradient = value;
    for (const version of this.versions) {
      if (version.cloud) version.cloud.material.gradient = value;
    }
  }

  get artifactNames() {
    return this.versions.map((x) => x.name);
  }

  showVersion(name) {
    // console.log(`>> showing version ${name}`)
    this.selectedVersion = this.versions.find((x) => x.name === name);
    this.selectedVersion.show();
    // ensure no other artifact is shown
    this.versions
      .filter((x) => x.name !== this.selectedVersion.name)
      .map((x) => x.hide());
  }

  zoom() {
    if (this.selectedVersion && this.selectedVersion.loaded) {
      // console.log(`>> zooming ${this.id} to version: ${this.selectedVersion.name}`)
      const box = this.db.vuepage.viewer.getBoundingBox([
        this.selectedVersion.cloud,
      ]);
      this.db.vuepage.zoomToBoundingBox(box);
    }
  }

  hide() {
    // console.log(`>> hiding all versions for scan ${this.id}`)
    this.selectedVersion.hide();
    this.hideLabel();
    this.hidePath();
    this.hideCameraLabel();
  }

  show(zoom = false) {
    // console.log(`>> showing scan ${this.id}`)
    this.selectedVersion.show(zoom);
  }

  showLabel() {
    // console.log(`>> showLabel for ${this.id}`)
    /* const displayedArtifact = this.artifacts.find(x => x.cloud.visible === true)
        if(!displayedArtifact)
            console.log(`no cloud is displayed for this scan..cannot label`)
        else {
            if (this.labelAnnotation)
                this.removeLabel()

            this.labelAnnotation = this.db.vuepage.viewer.scene.addAnnotation([
                    this.selectedArtifact.centerPoint.x,
                    this.selectedArtifact.centerPoint.y,
                    this.selectedArtifact.centerPoint.z
                ], {
                    "title": `${this.id}`,
                    "actions": []
                });
        } */
  }

  hideLabel() {
    // console.log(`>> hideLabel for ${this.id}`)
    /* if (this.labelAnnotation)
            this.db.vuepage.viewer.scene.removeAnnotation(this.labelAnnotation); */
  }

  showPath() {
    if (this.trajectoryPath === null) {
      // console.log(`>> showing scan path`)
      const lineMaterial = new THREE.LineMaterial({
        color: 0x00ff00,
        dashSize: 5,
        gapSize: 2,
        linewidth: 2,
        resolution: new THREE.Vector2(1000, 1000),
      });

      this.db.vuepage.viewer.addEventListener("update", () => {
        this.db.vuepage.viewer.renderer.getSize(lineMaterial.resolution);
      });

      this.trajectoryPath = new THREE.Line2(
        this.trajectoryLineGeometry,
        lineMaterial
      );
      this.trajectoryPath.name = "trajectory";
      this.db.vuepage.viewer.scene.scene.add(this.trajectoryPath);
    }
  }

  hidePath() {
    if (this.trajectoryPath) {
      // console.log(`>> removing scan path`)
      this.db.vuepage.viewer.scene.scene.remove(this.trajectoryPath);
      this.trajectoryPath = null;
    }
  }

  showCameraLabel(imageSet: ScanImageSet) {
    this.hideCameraLabel();
    if (imageSet === null) imageSet = this.scanSrc?.images[0];

    if (imageSet) {
      // console.log(`>> labelling camera location`)
      this.cameraLabelAnnotation = this.db.vuepage.viewer.scene.addAnnotation(
        [imageSet.X, imageSet.Y, imageSet.Z],
        {
          title: `Image Set #${parseInt(imageSet.id) + 1}`,
          actions: [],
        }
      );
    }
  }

  hideCameraLabel() {
    if (this.cameraLabelAnnotation) {
      // console.log(`>> removing camera annotation`)
      this.db.vuepage.viewer.scene.removeAnnotation(this.cameraLabelAnnotation);
    }
  }

  /*         getFirstPointOfPointCloud(pointCloud): number {
            const array = pointCloud.pcoGeometry.root.geometry.attributes.position.array;
            const i = 0;
            const x = array[i + 0];
            const y = array[i+ 1];
            const z = array[i + 2];
            const position = new THREE.Vector3(x, y, z);
            position.applyMatrix4(pointCloud.matrixWorld);
            return position;
        },  */
  /*         getAllPointsOfPointCloud(pointCloud) {
            const list = [];
            const array = pointCloud.pcoGeometry.root.geometry.attributes.position.array;
            let index = 0;
            for (let i = 0; i < pointCloud.pcoGeometry.root.geometry.attributes.position.length;i=i+3) {
                const x = array[i + 0];
                const y = array[i+ 1];
                const z = array[i + 2];
                const position = new THREE.Vector3(x, y, z);
                position.applyMatrix4(pointCloud.matrixWorld);
                list[index] = position;
                index++;
            }
            return list;
        }, */
}

class PCDBScene {
  scans: PCDBScan[] = [];
  // references to external things
  vuepage: any;
  listOfAttributes = [
    { value: "rgba", name: "colorized" },
    { value: "intensity", name: "intensity" },
    { value: "elevation", name: "elevation (gradient)" },
    { value: "gps-time", name: "time (gradient)" },
  ];
  private _focusedScan = null;
  private _pointsize: number;
  private _attribute: string;
  private _gradient: any;
  private _annotations = false;

  constructor(vuepage) {
    this.vuepage = vuepage;
  }

  get focusedScan() {
    return this._focusedScan;
  }

  get pointsize() {
    return this._pointsize;
  }

  set pointsize(value: number) {
    value = value / 10;
    /*         if(this.focusedScan)  {
            // console.log(`>> overriding pointsize for ${this.focusedScan.id} to ${value}`)
            this.focusedScan.pointsize = value;
        } else { */
    this._pointsize = value;
    // console.log(`>> setting point size to ${this._pointsize}`);
    for (const scan of this.scans) {
      scan.pointsize = this._pointsize;
    }
    /*         } */
  }

  get attribute() {
    // if there is a focused scan, return that one's attribute
    if (this.focusedScan) {
      return this.focusedScan.attribute;
    }
    // otherwise, just return the scene's
    return this._attribute;
  }

  set attribute(value: string) {
    if (this.focusedScan) {
      // console.log(`>> overriding attribute for ${this.selected.id} to ${value}`)
      this.focusedScan.attribute = value;
    } else {
      this._attribute = value;
      // console.log(`>> setting default attribute to ${this._attribute}`);
      this.scans.map((x) => (x.attribute = this._attribute));
    }
  }

  get attributeName(): string {
    return this.listOfAttributes.find((x) => x.value === this.attribute)?.name;
  }

  get gradient() {
    return this._gradient;
  }

  set gradient(value: string) {
    if (this.focusedScan) {
      // console.log(`>> overriding gradient for ${this.selected.id} to ${value}`)
      this.focusedScan.gradient = value;
    } else {
      // track the default gradient and set it for all
      this._gradient = value;
      // console.log(`>> setting default gradient to ${this._gradient}`)
      for (const scan of this.scans) {
        scan.gradient = this._gradient;
      }
    }
  }

  /*     chosenAttributeFocused(){
        if(this.vuepage.focusedScanId){
            for(const name of this.listOfAttributes){
                if(name.value === this._focusedScan?.selectedVersion?.cloud?.material?.activeAttributeName){
                    return name?.name;
                }
            }
        }
    }      */

  loadScan(scan: Scan) {
    let count = 0;
    if (!this.scans.find((x) => x.id === scan.id)) {
      // create the scan entry
      // console.log(`>> creating new PCDBScan entry for ${scan.id}`);
      this.scans.push(new PCDBScan(this, scan));
    }

    for (const scan of this.scans) {
      for (const version of scan.versions) {
        if (version.cloud?.visible) {
          count += 1;
        }
      }
    }

    if (count > 0) {
      this.scans.find((x) => x.id === scan.id).show();
    } else {
      this.scans.find((x) => x.id === scan.id).show(true);
    }
    // find the scan and show it.
    // const firstShownScan = (this.scans.length === 1)
    // this.scans.find(x => x.id === scan.id ).show(firstShownScan) // zoom only on the first scan loaded
    // if it is focused, focus it.
    if (this.vuepage.focusedScanId === scan.id) this.focusScan(scan.id);
  }

  focusScanByCloud(cloud): PCDBScan {
    for (const scan of this.scans) {
      for (const version of scan.versions) {
        if (version.cloud === cloud) {
          this.focusScan(scan.id);
          return scan;
        }
      }
    }
    return null;
  }

  focusScan(id: string, imageSet: ScanImageSet = null) {
    if (!this._focusedScan || this._focusedScan.id !== id) {
      this.clearFocus();
    }
    this._focusedScan = this.scans.find((x) => x.id === id);
    if (!this._focusedScan) {
      // console.log(`>> failed attempt to select scan ${id}, not yet loaded`);
    } else {
      // console.log(`>> selecting scan ${this._focusedScan.id}`);
      if (imageSet) {
        //this.zoom(this._selectedScan.id)  // disruptive.. prefer to not zoom on select
        if (this._annotations) this._focusedScan.showPath();
        if (this._annotations) this._focusedScan.showCameraLabel(imageSet);
      }
    }
  }

  clearFocus() {
    if (this._focusedScan) {
      // console.log(`>> unselecting selected scan ${this._focusedScan}`);
      this._focusedScan.hideLabel();
      this._focusedScan.hidePath();
      this._focusedScan.hideCameraLabel();
      this._focusedScan = null;
    }
    // console.log(` clearFocus ${this.selected} `)
  }

  zoomScan(id) {
    if (id) {
      // console.log(`>> scene zoom to ${id}`)
      const scan = this.scans.find((x) => x.id === id);
      if (scan) {
        scan.zoom();
      }
    }
  }

  // sets a flag to show annotations and then selects the scan, which will show the annotations
  showAnnotations(id, imageSet: ScanImageSet = null) {
    // console.log(`>> show camera annotations for Scan ${id}`)
    this._annotations = true;
    this.focusScan(id, imageSet);
  }

  // sets a flag to hide annotations and then hides the scan
  hideAnnotations() {
    // console.log(`>> hide camera annotations`)
    this._annotations = false;
    if (this._focusedScan) {
      this._focusedScan.hidePath();
      this._focusedScan.hideCameraLabel();
    }
  }
}

// --------------------------------
// Vue page starts
// --------------------------------
export default {
  name: "PointcloudView",
  components: {
    ProcessPDAL,
    PublishScan,
  },
  props: {
    showFullMenu: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    positions: {
      clientX: undefined,
      clientY: undefined,
      movementX: 0,
      movementY: 0,
    },
    point: {
      coordinates: {
        x: "",
        y: "",
        z: "",
      },
      intensity: [],
    },
    selectedBox: null,
    clip: null,
    edit: false,
    create: false,
    panel: null,
    projection: "",
    boundingBoxCoordinates: {
      minx: null,
      miny: null,
      minz: null,
      maxx: null,
      maxy: null,
      maxz: null,
    },
    newComment: null,
    newTitle: null,
    chosenAnnotation: null,
    domElement: {},
    pointExplorer: false,
    processOption: null,
    dialog: false,
    processingComponent: null,
    cloudDB: null,
    viewer: Object,
    selectedPointsize: 4,
    selectedAttribute: "rgba",
    defaultScheme: Potree.Gradients.YELLOW_GREEN, //PLASMA, INFERNO, GRAYSCALE, YELLOW_GREEN, VIRIDIS, SPECTRAL
  }),
  computed: {
    ...mapGetters(["canProcess"]),
    ...mapGetters(["isLoading"]),
    ...mapGetters("viewer", [
      "selectedAnnotationsList",
      "selectedScanIdList",
      "loadedScanList",
      "visibleScanIdList",
      "isScanSelected",
      "isScanLoaded",
      "isScanVisible",
      "focusedScanId",
      "focusedPoint",
      "focusedImageSet",
      "defaultVersion",
      "loadedScan",
      "imageViewVisible",
      "summaryScanList",
      "focusedScan",
      "autoFocus",
      "filteredAnnotationsList",
      "annotationsByProjectList",
      "focusedAnnotation",
      "focusToAnnotation",
      "annotations",
      "removeClip",
    ]),
    defaultVersion() {
      return this.cloudDB?.focusedScan?.scanSrc?.defaultVersion;
    },
    isBoundingBox() {
      if (this.viewer.scene?.volumes.length === 0) {
        return false;
      } else {
        return true;
      }
    },
    processingPermission() {
      if (this.user?.userType === "Standard") {
        return false;
      }
      return true;
    },
    attributes() {
      return this.cloudDB ? this.cloudDB.listOfAttributes : [];
    },
    artifactList() {
      return this.cloudDB?.focusedScan
        ? this.cloudDB.focusedScan.artifactNames
        : [];
    },
    selectedScan() {
      return this.cloudDB?.focusedScan;
    },
    selectedVersion() {
      return this.cloudDB?.focusedScan?.selectedVersion?.name;
    },
    displayAttribute() {
      return this.cloudDB?.focusedScan
        ? this.cloudDB?.attributeName
        : this.attributes.find((x) => x.value === this.selectedAttribute)?.name;
    },
    selectedVersionId() {
      return this.cloudDB?.focusedScan?.selectedVersion?.id;
    },
    clouds() {
      if (this.viewer && this.viewer.scene) {
        return this.viewer.scene.pointclouds.filter((x) => x.visible === true);
      } else {
        return [];
      }
    },
    showGradients() {
      return (
        ["elevation (gradient)", "time (gradient)"].indexOf(
          this.displayAttribute
        ) >= 0
      );
    },
  },
  mounted: function () {
    // instantiate the helper function
    this.cloudDB = new PCDBScene(this);
    this.cloudDB.pointsize = this.selectedPointsize;
    this.cloudDB.attribute = this.selectedAttribute;
    this.cloudDB.gradient = this.defaultScheme;
    // initialize the point viewer on a 1/2s timeout to avoid race conditions while unloading any previous Potree.Viewer
    setTimeout(() => {
      // console.log('creating new Potree.Viewer...');
      this.viewer = new Potree.Viewer(this.$refs.potree_render_area_ref);

      /*             // set credentials for potree viewer
            this.viewer.customHeaders = [
                { header: 'Authorization', value: 'SomeKey' }
            ]; */

      // this line is here because some of the controls (like the camera controls), construct with window.viewer hardcoded as the reference to the viewer.
      window.viewer = this.viewer;

      this.viewer.addEventListener("render.pass.scene", () => {
        if (this.viewer?.scene?.scene) {
          this.scaleSpheres();
        }
      });

      // i-dome lighting rendering technique
      this.viewer.setEDLEnabled(true);
      this.viewer.compass.setVisible(true);
      this.viewer.setFOV(10);
      this.viewer.setPointBudget(10 * 1000 * 1000);
      this.viewer.setBackground("gradient"); // ["skybox", "gradient", "black", "white"] or null;
      this.viewer.loadSettingsFromURL();
      //this.viewer.useHQ = true;

      this.viewer.loadGUI(() => {
        this.viewer.setLanguage("en");
        //this.$("#menu_tools").next().show();
        if (this.showFullMenu) {
          this.viewer.toggleSidebar();
        }
      });
      // add an event lister
      // originally, the listening event was on mousedown

      this.viewer.renderer.domElement.addEventListener("click", (e) => {
        // find intersection
        const mouse = this.viewer.inputHandler.mouse;
        const camera = this.viewer.scene.getActiveCamera();
        const hit = Potree.Utils.getMousePointCloudIntersection(
          mouse,
          camera,
          this.viewer,
          this.viewer.scene.pointclouds
        );

        const ray = Potree.Utils.mouseToRay(
          mouse,
          camera,
          this.viewer.renderer.domElement.clientWidth,
          this.viewer.renderer.domElement.clientHeight
        );
        const raycaster = new THREE.Raycaster();
        raycaster.ray.set(ray.origin, ray.direction);
        raycaster.params.Line.threshold = 0.2;
        const intersections = raycaster.intersectObjects(
          this.viewer.scene.volumes
        );
        const box = intersections[0]?.object?.id;
        if (box) {
          this.selectedBox = this.viewer.scene.volumes.find(
            (x) => x.id === box
          );
        } else {
          this.selectedBox = null;
        }

        // shift and select of a point cloud will label the point cloud with the id, and focus the polint
        if (e.shiftKey) {
          if (hit !== null) {
            const annotation = this.annotations.find(
              (x) =>
                x.point.coordinates[0].toFixed(6) ===
                hit.point.position.x.toFixed(6)
            );
            if (annotation) {
              this.closePointData();
              this.$store.dispatch("viewer/selectAnnotation", annotation);
            } else {
              if (this.pointExplorer === true) {
                this.point.coordinates.x = hit.point.position.x;
                this.point.coordinates.y = hit.point.position.y;
                this.point.coordinates.z = hit.point.position.z;
                this.projection = hit.pointcloud.projection;
                this.point.intensity = hit.point.intensity;
                this.removeHighlightedPoint();
                this.highlightPoint(this.point.coordinates);
              } else {
                const scan = this.cloudDB.focusScanByCloud(hit.pointcloud);
                this.$store.dispatch("viewer/focusScanId", scan.id);
                this.$store.dispatch("viewer/focusPoint", {
                  x: hit.location.x,
                  y: hit.location.y,
                });
              }
            }
          } else {
            this.cloudDB.clearFocus();
            this.$store.dispatch("viewer/focusScanId", null);
            this.$store.dispatch("viewer/focusPoint", null);
          }
        } else {
          // don't do anything if shift is not clicked (allow panning, movement)
        }
      });

      this.reconcileLoad();
    }, 500);

    this.refreshMenuGradients();
  },
  destroyed: function () {
    // console.log('deleting Potree.Viewer...');
    this.viewer.scene.pointclouds = [];
    this.viewer.scene.scene.dispose();
    this.viewer.scene.sceneBG.dispose();
    this.viewer.scene.scenePointCloud.dispose();
    delete this.viewer;
  },
  methods: {
    openPointData() {
      this.pointExplorer = true;
    },
    closePointData() {
      this.pointExplorer = false;
      this.point.coordinates.x = null;
      this.point.coordinates.y = null;
      this.point.coordinates.z = null;
      this.projection = null;
      this.point.intensity = null;
      this.removeHighlightedPoint();
    },
    scaleSpheres() {
      const spheres = this.viewer.scene.scene.children.filter(
        (x) =>
          /*x.geometry?.type === "SphereGeometry" &&*/ x.name ===
            "annotation" || x.name === "point data"
      );
      const camera = this.viewer.scene.getActiveCamera();
      const domElement = this.viewer.renderer.getSize(new THREE.Vector2());
      for (const sphere of spheres) {
        const clientWidth = domElement.x;
        const clientHeight = domElement.y;
        const distance = camera.position.distanceTo(
          sphere.getWorldPosition(new THREE.Vector3())
        );
        const pr = Potree.Utils.projectedRadius(
          1,
          camera,
          distance,
          clientWidth,
          clientHeight
        );
        sphere.scale.set(15 / pr, 15 / pr, 15 / pr);
      }
    },
    openProcessOption(item) {
      if (item === "Process") {
        this.processingComponent = "ProcessPDAL";
        this.calculateBoundingBox();
        this.$store.dispatch(
          "viewer/setBoundingBoxes",
          this.boundingBoxCoordinates
        );
      } else {
        this.processingComponent = "PublishScan";
      }
      this.dialog = true;
    },
    closeProcessOption() {
      this.boundingBoxCoordinates = {
        minx: null,
        miny: null,
        minz: null,
        maxx: null,
        maxy: null,
        maxz: null,
      };
      this.$store.dispatch(
        "viewer/setBoundingBoxes",
        this.boundingBoxCoordinates
      );
      this.dialog = false;
    },
    focusAllPointClouds() {
      const visibleClouds = this.viewer.scene.pointclouds.filter(
        (x) => x.visible
      );
      const box = this.viewer.getBoundingBox(visibleClouds);
      this.zoomToBoundingBox(box);
    },
    setDefaultVersion(selectedVersion) {
      this.$apollo.mutate({
        mutation: UPDATE_SCAN_HEADER,
        variables: {
          id: this.focusedScan.id,
          defaultVersion: selectedVersion,
        },
      });
      this.cloudDB.focusedScan.scanSrc.defaultVersion = selectedVersion;
    },
    selectVersion(version) {
      // console.log(`selecting version: ${version}`)
      if (this.cloudDB.focusedScan.selectedVersion.name !== version)
        this.cloudDB.focusedScan?.showVersion(version);
    },
    selectAttribute(attribute) {
      // console.log(`selectAttribute ${attribute}`)
      this.selectedAttribute = attribute;
    },
    refreshMenuGradients() {
      // dynamically build the palette for point cloud gradient schemes
      if (!this.showFullMenu) {
        // console.log('creating gradients')
        const schemes = Object.keys(Potree.Gradients).map((name) => ({
          name: name,
          values: Potree.Gradients[name],
        }));
        const elGradientSchemes = document.getElementById("gradient_schemes");

        for (const scheme of schemes) {
          const elButton = document.createElement("span");
          const svg = Potree.Utils.createSvgGradient(scheme.values);
          svg.setAttributeNS(null, "class", `button-icon`);
          svg.style.height = "1.4em";
          svg.style.width = "0.9em";
          elButton.append(svg);

          elButton.addEventListener("click", () => {
            this.cloudDB.attribute = this.selectedAttribute;
            this.cloudDB.gradient = Potree.Gradients[scheme.name];
          });
          elGradientSchemes?.append(elButton);
        }
      }
    },
    /* Put ref="draggableContainer" id="draggableContainer" @mousedown="dragMouseDown" on div you want to move */
    // dragMouseDown: function (event) {
    //     event.preventDefault()
    //     // get the mouse cursor position at startup:
    //     this.positions.clientX = event.clientX
    //     this.positions.clientY = event.clientY
    //     document.onmousemove = this.elementDrag
    //     document.onmouseup = this.closeDragElement
    // },
    // elementDrag: function (event) {
    //     event.preventDefault()
    //     this.positions.movementX = this.positions.clientX - event.clientX
    //     this.positions.movementY = this.positions.clientY - event.clientY
    //     this.positions.clientX = event.clientX
    //     this.positions.clientY = event.clientY
    //     // set the element's new position:
    //     this.$refs.draggableContainer.style.top = (this.$refs.draggableContainer.offsetTop - this.positions.movementY) + 'px'
    //     this.$refs.draggableContainer.style.left = (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px'
    // },
    // closeDragElement () {
    //     document.onmouseup = null
    //     document.onmousemove = null
    // },
    // updateAnnotation(comment) {
    //   this.$apollo
    //     .mutate({
    //       mutation: UPDATE_ANNOTATION,
    //       variables: {
    //         id: this.chosenAnnotation.id,
    //         scanId: this.chosenAnnotation.scan.id,
    //         comment: comment,
    //         type: this.chosenAnnotation.type,
    //         point: {
    //           type: this.chosenAnnotation.point.type,
    //           coordinates: this.chosenAnnotation.point.coordinates,
    //         },
    //       },
    //       refetchQueries: [{ query: GET_ANNOTATIONS }],
    //     })
    //     .then(() => {
    //       this.unselectAnnotation();
    //       this.panel = null;
    //     });
    // },
    // deleteAnnotation(annotation) {
    //   this.$apollo
    //     .mutate({
    //       mutation: DELETE_ANNOTATION,
    //       variables: {
    //         id: Number(annotation),
    //       },
    //     })
    //     .then(() => {
    //       const annotation = this.annotations.find(
    //         (x) => x.id === this.chosenAnnotation.id
    //       );
    //       const index = this.annotations.indexOf(annotation);
    //       this.annotations.splice(index, 1);
    //       this.closePointData();
    //       this.highlightAnnotatedPoints();
    //       this.panel = null;
    //       this.$store.dispatch("viewer/setAnnotations");
    //     });
    // },
    createAnnotation(title, comment) {
      if (comment === null || title === null) {
        return null;
      }
      this.$apollo
        .mutate({
          mutation: ADD_ANNOTATION,
          variables: {
            scanId: this.focusedScanId,
            title: title,
            comment: comment,
            type: "Point",
            point: {
              type: "Point",
              coordinates: [
                this.point.coordinates.x,
                this.point.coordinates.y,
                this.point.coordinates.z,
              ],
            },
          },
        })
        .then((data) => {
          const annotation = { ...data.data.createAnnotation };
          this.annotations.unshift(annotation);
          this.newComment = null;
          this.newTitle = null;
          this.closePointData();
          this.$store.dispatch("viewer/selectAnnotation", annotation);
        });
    },
    measuringTool() {
      this.viewer.measuringTool.startInsertion({
        showDistances: true,
        showArea: false,
        closed: false,
        name: "Distance",
      });
    },
    volumeTool() {
      if (this.visibleScanIdList.length > 0) {
        this.viewer.volumeTool.startInsertion({ clip: true });
        this.clip = null;
      }
      for (const volumes of this.viewer.scene.volumes) {
        volumes.label.visible = true;
      }
    },
    removeSelectedBox() {
      if (this.selectedBox) {
        this.viewer.scene.removeVolume(this.selectedBox);
      }
    },
    measuringToolHeight() {
      this.viewer.measuringTool.startInsertion({
        showDistances: true,
        showHeight: true,
        showArea: false,
        closed: false,
        name: "Distance",
      });
    },
    removeAllMeasurements() {
      this.viewer.scene.removeAllMeasurements();
    },
    zoomToBoundingBox(boundingBox) {
      const node = new THREE.Object3D();
      node.boundingBox = boundingBox;
      this.viewer.zoomTo(node, 1, 700);
      this.viewer.controls.stop();
    },
    async reconcileLoad() {
      // this actually forces the loading of the point cloud.
      const toLoad = this.loadedScanList.filter(
        (x) => !this.cloudDB.scans.some((scan) => scan.id === x.id)
      );
      toLoad.map((scan) => this.cloudDB.loadScan(scan));
    },
    reconcileShow() {
      for (const scan of this.cloudDB.scans) {
        if (!this.isScanSelected(scan.id) || !this.isScanVisible(scan.id)) {
          scan.hide();
        } else {
          scan.show();
        }
      }
    },
    calculateBoundingBox() {
      /*     Matrix4 m = new Matrix4()
        .translate(px, py, pz) // <- translate to position
        .rotateXYZ(ax, ay, az) // <- rotation about x, then y, then z
        .scale(sx, sy, sz);    // <- scale */
      /* Compute cube corners and print them */
      if (this.viewer.scene.volumes.length > 0) {
        for (const box in this.viewer.scene.volumes) {
          const position = this.viewer.scene.volumes[box].position;
          // console.log("position");
          // console.log(position);
          const scale = this.viewer.scene.volumes[box].scale;
          // console.log("scale");
          // console.log(scale);
          const rotation = this.viewer.scene.volumes[box].rotation;
          // console.log("rotation");
          // console.log(rotation);
          // console.log("calc corners");
          const m = this.viewer.scene.volumes[box].matrix;
          // console.log(m);
          const points = [];
          for (const xD of [-1, 1]) {
            for (const yD of [-1, 1]) {
              for (const zD of [-1, 1]) {
                const newPoint = new THREE.Vector3(xD / 2, yD / 2, zD / 2);
                newPoint.applyMatrix4(m);
                // console.log(
                //   `point location {${xD}, ${yD}, ${zD}} = {${newPoint.x}, ${newPoint.y}, ${newPoint.z}`
                // );
                points.push([newPoint.x, newPoint.y, newPoint.z]);
              }
            }
          }
          this.boundingBoxCoordinates.minx = points[0][0];
          this.boundingBoxCoordinates.miny = points[0][1];
          this.boundingBoxCoordinates.minz = points[0][2];
          this.boundingBoxCoordinates.maxx = points[7][0];
          this.boundingBoxCoordinates.maxy = points[7][1];
          this.boundingBoxCoordinates.maxz = points[7][2];
        }
      }
    },
    removeHighlightedPoint() {
      const spheres = this.viewer.scene.scene.children.filter(
        (x) => x.name !== "point data"
      );
      this.viewer.scene.scene.children = spheres;
    },
    highlightPoint(point) {
      //console.log(point)
      const camera = this.viewer.scene.getActiveCamera();
      const domElement = this.viewer.renderer.getSize(new THREE.Vector2());
      const geometry = new THREE.SphereGeometry(0.4, 10, 10);
      const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
      const sphere = new THREE.Mesh(geometry, material);
      sphere.position.set(point.x, point.y, point.z);

      const clientWidth = domElement.x;
      const clientHeight = domElement.y;
      const distance = camera.position.distanceTo(
        sphere.getWorldPosition(new THREE.Vector3())
      );
      const pr = Potree.Utils.projectedRadius(
        1,
        camera,
        distance,
        clientWidth,
        clientHeight
      );
      sphere.scale.set(15 / pr, 15 / pr, 15 / pr);
      sphere.name = "point data";
      this.viewer.scene.scene.add(sphere);
    },
    highlightCorners(point) {
      // used in testing the volume rotation/translation/scale
      const camera = this.viewer.scene.getActiveCamera();
      const domElement = this.viewer.renderer.getSize(new THREE.Vector2());
      const geometry = new THREE.SphereGeometry(0.4, 10, 10);
      const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
      const sphere = new THREE.Mesh(geometry, material);
      sphere.position.set(point.x, point.y, point.z);

      const clientWidth = domElement.x;
      const clientHeight = domElement.y;
      const distance = camera.position.distanceTo(
        sphere.getWorldPosition(new THREE.Vector3())
      );
      const pr = Potree.Utils.projectedRadius(
        1,
        camera,
        distance,
        clientWidth,
        clientHeight
      );
      sphere.scale.set(15 / pr, 15 / pr, 15 / pr);
      sphere.name = "bounding box";
      this.viewer.scene.scene.add(sphere);
    },
    zoomToAnnotation() {
      if (this.focusToAnnotation === true) {
        const box = new THREE.Box3();
        box.min.x = this.focusedAnnotation?.point.coordinates[0];
        box.min.y = this.focusedAnnotation?.point.coordinates[1];
        box.min.z = this.focusedAnnotation?.point.coordinates[2];
        box.max.x = this.focusedAnnotation?.point.coordinates[0];
        box.max.y = this.focusedAnnotation?.point.coordinates[1];
        box.max.z = this.focusedAnnotation?.point.coordinates[2] + 5;
        const node = new THREE.Object3D();
        node.boundingBox = box;
        this.viewer.zoomTo(node, 1, 700);
        this.viewer.controls.stop();
        this.$store.dispatch("viewer/setFocusToAnnotation", false);
      }
    },
    highlightAnnotation() {
      for (const annotation of this.selectedAnnotationsList) {
        const camera = this.viewer.scene.getActiveCamera();
        const domElement = this.viewer.renderer.getSize(new THREE.Vector2());
        const geometry = new THREE.SphereGeometry(0.4, 10, 10);
        const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
        const sphere = new THREE.Mesh(geometry, material);
        sphere.position.set(
          annotation.point.coordinates[0],
          annotation.point.coordinates[1],
          annotation.point.coordinates[2]
        );
        const clientWidth = domElement.x;
        const clientHeight = domElement.y;
        const distance = camera.position.distanceTo(
          sphere.getWorldPosition(new THREE.Vector3())
        );
        const pr = Potree.Utils.projectedRadius(
          1,
          camera,
          distance,
          clientWidth,
          clientHeight
        );
        sphere.scale.set(15 / pr, 15 / pr, 15 / pr);
        sphere.name = `annotation`;
        this.viewer.scene.scene.add(sphere);
      }
    },
    removeHighlightedAnnotation() {
      const spheres = this.viewer.scene?.scene?.children?.filter(
        (x) => x.name !== "annotation"
      );
      this.viewer.scene.scene.children = spheres;
    },
  },

  watch: {
    clip() {
      if (this.clip === "Clip") {
        this.viewer.clipTask = 2;
      } else if (this.clip === "Unclipped") {
        this.viewer.clipTask = 3;
      } else {
        this.viewer.clipTask = 1;
      }
    },
    selectedAnnotationsList() {
      if (this.viewer?.scene?.scene) {
        this.removeHighlightedAnnotation();
        this.highlightAnnotation();
      }
    },
    selectedPointsize() {
      this.cloudDB.pointsize = this.selectedPointsize;
    },
    selectedAttribute() {
      this.cloudDB.attribute = this.selectedAttribute;
    },
    selectedVersion() {
      // console.log(`*** pointcloudview selectedVersion ${this.selectedVersion}`)
      this.$store.dispatch("viewer/focusVersion", this.selectedVersion);
    },
    selectedScanIdList: function () {
      // console.log('*** pointcloudview selectedScanIdList');
      this.reconcileShow();
    },
    focusedScanId: function () {
      // console.log(`*** pointcloudview focusedScanId ${this.focusedScanId}`);
      this.closePointData();
      this.cloudDB.focusScan(this.focusedScanId);
      this.cloudDB.zoomScan(this.focusedScanId);
    },
    loadedScan: function () {
      // console.log('*** pointcloudview loadedScan')
    },
    visibleScanIdList: function () {
      // console.log('*** pointcloudview visibleScanIdList');
      this.reconcileShow();
    },
    loadedScanList: function () {
      // console.log('*** pointcloudview loadedScanList');
      this.reconcileLoad();
    },
    focusedImageSet: function () {
      // console.log(`*** pointcloudview FocusedImageSet ${this.focusedScanId} ${this.focusedImageSet}`);
      this.cloudDB.focusScan(this.focusedScanId, this.focusedImageSet);
      //this.$nextTick(() => {
      //  if(this.cloudDB.selected && this.cloudDB.selected.selectedVersion)
      //    this.selectedVersion = this.cloudDB.selected.selectedVersion.name;
      //})
    },
    imageViewVisible: function () {
      // console.log(`*** pointcloudview imageViewVisible ${this.imageViewVisible} ${this.focusedScanId} ${this.focusedImageSet}`);
      if (this.imageViewVisible)
        this.cloudDB.showAnnotations(this.focusedScanId, this.focusedImageSet);
      else this.cloudDB.hideAnnotations();
    },
    focusToAnnotation() {
      this.zoomToAnnotation();
    },
    removeClip() {
      if (this.removeClip !== null) {
        this.clip = null;
      }
    },
  },
};
