import { ActivityLogger } from "../services/activity-logger";
import { GET_USER_CONTEXT } from "../graphql/org-user-queries";
import { MAPSTYLES } from "@/contants";
import Vue from "vue";
import Vuex from "vuex";
import { apolloClient } from "../services/vue-apollo";
import fileUploadStoreModule from "./file-upload-store";
import { getRouter } from "../router";
import viewerStoreModule from "./viewer-store";

const mutations = {
  SET_LOGGED_IN: "SET_LOGGED_IN",
  SET_LOGGED_OUT: "SEE_LOGGED_OUT",
};

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    fileUpload: fileUploadStoreModule,
    viewer: viewerStoreModule,
  },
  state: {
    loading: 0,
    loggedIn: false,
    username: null,
    awsUserId: null,
    user: null,
    selectedMapStyle: "satellite streets", // TODO: move to viewer?
    snackbar: {
      content: "",
      color: "",
      timeout: "",
    },
  },
  mutations: {
    showMessage(state, payload) {
      console.log("showMessage");
      state.snackbar.content = payload.content;
      state.snackbar.color = payload.color;
      state.snackbar.timeout = "timeout" in payload ? payload.timeout : 8000;
    },
    [mutations.SET_LOGGED_IN](state) {
      console.log("SET LOGGED IN TRUE");
      state.loggedIn = true;
    },
    [mutations.SET_LOGGED_OUT](state) {
      console.log("SET_LOGGED_OUT");
      state.loggedIn = false;
    },
    startLoading(state) {
      console.log("startLoading");
      state.loading += 1;
    },
    stopLoading(state) {
      console.log("stopLoading");
      state.loading -= 1;
    },
    setUsername(state, username) {
      console.log("setUsername");
      state.username = username;
    },
    setUser(state, user) {
      console.log("setUser");
      state.user = user;
    },
    setAwsUserId(state, awsUserId) {
      console.log("setAwsUserId");
      state.awsUserId = awsUserId;
    },
    setMapStyle(state, mapStyle) {
      console.log("setMapStyle");
      state.selectedMapStyle = mapStyle;
    },
  },
  actions: {
    setMapStyle({ commit }, style) {
      commit("setMapStyle", style);
    },
    setLoggedIn({ commit, dispatch }, cognitoUser) {
      const activityLogger = new ActivityLogger();
      activityLogger.logEvent("UserLogin");

      commit("setUsername", cognitoUser.username);
      commit("setAwsUserId", cognitoUser.sub);
      dispatch("refreshUserContext");
    },
    setLoggedOut({ commit }) {
      commit(mutations.SET_LOGGED_OUT);
      commit("setUsername", null);
      commit("setAwsUserId", null);
      commit("setUser", null);
    },
    refreshUserContext({ commit }) {
      commit("startLoading");
      apolloClient
        .query({
          query: GET_USER_CONTEXT,
          variables: { awsUserId: store.state.awsUserId },
          fetchPolicy: "network-only",
        })
        .then((res) => {
          console.log("RES", res);
          if (res.data.user && res.data.user.enabled) {
            commit("setUser", res.data.user);
            commit(mutations.SET_LOGGED_IN);
          } else {
            console.log("user not enabled");
            store.dispatch("setLoggedOut");
            getRouter().push({
              name: "Unauthorized",
            });
          }
        })
        .catch((err) => {
          console.log("error loading user context");
          console.log(err);
          store.dispatch("setLoggedOut");
        })
        .finally(() => {
          //console.log('refreshed user context');
          commit("stopLoading");
        });
    },
  },
  getters: {
    user: (state) => state.user,
    isLoading: (state) => state.loading > 0,
    loading: (state) => state.loading,
    loggedIn: (state) => state.loggedIn,
    mapStyleNames(): string[] {
      return MAPSTYLES.map((x) => x[0]);
    },
    selectedMapStyle: (state) => state.selectedMapStyle,
    selectedMapStyleURL(state): string {
      return MAPSTYLES.find((x) => x[0] === state.selectedMapStyle)[1];
    },
    canProcess(state) {
      if (
        state.user?.userType === "Admin" ||
        state.user?.userType === "Power User"
      )
        return true;
      return false;
    },
    isPowerUser(state) {
      if (state.user?.userType === "Power User") return true;
      return false;
    },
  },
});

export default store;
