/* eslint-disable */
// src/services/auth.ts

import { CognitoAuth, StorageHelper } from "amazon-cognito-auth-js";
import { removeApolloAuthToken, setApolloAuthToken } from "./vue-apollo";

import { apolloProvider } from "./vue-apollo"; // Ensure you have access to the Apollo client
import axios from "axios";
import { getGlobalConfig } from "../config/config.js"; // Import the getter function
import { getRouter } from "../router"; // Correct import to access router instance
import store from "../store/store";

// Initialize CognitoAuth as null
let awsAuthService: CognitoAuth | null = null;

/**
 * Initializes CognitoAuth with the loaded configuration.
 */
export const initializeAuth = () => {
  const config = getGlobalConfig();

  console.log("get config", config);

  if (!config?.domain_name) {
    throw new Error("Cognito configuration is missing!");
  }

  const authConfig = {
    ClientId: config.aws_user_pools_web_client_id,
    AppWebDomain: config.oauth_domain,
    TokenScopesArray: config.supported_logins.split(","),
    RedirectUriSignIn: config.redirect_sign_in,
    RedirectUriSignOut: config.redirect_sign_out,
    UserPoolId: config.aws_user_pools_id,
  };

  console.log("auth config", authConfig);

  awsAuthService = new CognitoAuth(authConfig);

  awsAuthService.userhandler = {
    onSuccess() {
      console.log("Successful userHandler auth... navigating to /");
      try {
        getRouter().push("/");
      } catch (error) {
        console.error("Router instance is not available:", error);
      }
    },
    onFailure(err) {
      console.log(
        "Authentication failed... removing Apollo auth token, setting user to logged out, and redirecting to login"
      );
      // Remove Apollo Auth Token and update store
      removeApolloAuthToken(apolloProvider.defaultClient); // Pass the Apollo client
      store.dispatch("setLoggedOut");
      try {
        getRouter().push("/login");
      } catch (error) {
        console.error("Router instance is not available:", error);
      }
    },
  };
};

// Helper functions
export function getJwtToken(): string {
  if (!awsAuthService) {
    throw new Error("Auth service not initialized.");
  }
  const session = awsAuthService.getSignInUserSession();
  if (!session) {
    throw new Error("User session is not available.");
  }
  return session.getAccessToken().getJwtToken();
}

export function getAuthHeader() {
  return {
    headers: {
      Authorization: "Bearer " + getJwtToken(),
    },
  };
}

function getUserInfoStorageKey(): string {
  if (!awsAuthService) {
    throw new Error("Auth service not initialized.");
  }
  const keyPrefix =
    "CognitoIdentityServiceProvider." + awsAuthService.getClientId();
  const tokenUserName = awsAuthService
    .getSignInUserSession()
    .getAccessToken()
    .getUsername();
  const userInfoKey = keyPrefix + "." + tokenUserName + ".userInfo";
  return userInfoKey;
}

async function getAuthenticatedUser(): Promise<string> {
  if (!awsAuthService) {
    throw new Error("Auth service not initialized.");
  }

  const USERINFO_URL = `https://${awsAuthService.getAppWebDomain()}/oauth2/userInfo`;
  return axios.get(USERINFO_URL, getAuthHeader()).then((response) => {
    if (!store.getters.loggedIn) {
      setApolloAuthToken(apolloProvider.defaultClient, getJwtToken()).then(
        () => {
          // Pass the Apollo client
          store.dispatch("setLoggedIn", response.data);
          return store.getters.username;
        }
      );
    } else {
      return store.getters.username;
    }
  });
}

function parseCognitoWebResponse(url: string) {
  if (!awsAuthService) {
    throw new Error("Auth service not initialized.");
  }
  try {
    console.log("parseCognitoWebResponse", url);
    awsAuthService.parseCognitoWebResponse(url);
  } catch (e) {
    console.log("parseCognitoWebResponse error", e);
  }
}

// Initialize storage
const storageHelper = new StorageHelper();
const storage = storageHelper.getStorage();

export default {
  /**
   * Initiates the Cognito sign-in process.
   */
  login() {
    if (!awsAuthService) {
      throw new Error("Auth service not initialized.");
    }
    awsAuthService.getSession();
  },

  /**
   * Logs out the user by clearing tokens and updating the store.
   */
  logout() {
    store.dispatch("setLoggedOut");
    removeApolloAuthToken(apolloProvider.defaultClient); // Pass the Apollo client
    if (awsAuthService && awsAuthService.isUserSignedIn()) {
      awsAuthService.signOut();
      storage.removeItem(getUserInfoStorageKey());
    }
  },

  /**
   * Retrieves the authenticated user's username.
   * @returns {Promise<string>} The username.
   */
  getAuthenticatedUser,

  /**
   * Parses the Cognito web response from the URL.
   * @param {string} url - The URL containing the Cognito response.
   */
  parseCognitoWebResponse,
};
