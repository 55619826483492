import { ApolloLink, concat } from "apollo-link";

import ApolloClient from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
//import { setContext } from 'apollo-link-context'
//import { CognitoAccessToken } from 'amazon-cognito-identity-js';
import Vue from "vue";
import VueApollo from "vue-apollo";
import { createHttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import store from "@/store/store";

// Name of the localStorage item
const AUTH_TOKEN = "luxweb";
// Http endpoint

// Files URL root
const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP;
const filesRoot =
  process.env.VUE_APP_FILES_ROOT ||
  httpEndpoint.substr(0, httpEndpoint.indexOf("/graphql"));
Vue.prototype.$filesRoot = filesRoot;

const link = ApolloLink.from([
  onError(({ graphQLErrors, networkError }) => {
    // graphql error
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) => {
        // // eslint-disable-next-line no-console
        // console.log(
        //   "%cError",
        //   "background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;",
        //   message
        // );
        store.commit("showMessage", {
          content: message,
          color: "red",
          timeout: 6000,
        });
      });
    // network error
    if (networkError) {
      //
    }
  }),
  createHttpLink({ uri: httpEndpoint, fetch }),
]);

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const token = localStorage.getItem(AUTH_TOKEN);
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    },
  });
  return forward(operation);
});

const apolloClient = new ApolloClient({
  link: concat(authMiddleware, link),
  cache: new InMemoryCache(),
});

// Create vue apollo provider
export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    $query: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "all",
    },
  },
});

// Manually call this when user log in
export async function setApolloAuthToken(apolloClient, token) {
  if (typeof localStorage !== "undefined" && token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
  if (apolloClient) {
    // if (apolloClient.wsClient)
    //     restartWebsockets(apolloClient.wsClient)
    try {
      // await apolloClient.resetStore();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(
        "%cError on cache reset (login)",
        "color: orange;",
        e.message
      );
    }
  }
}

// Manually call this when user log out
export async function removeApolloAuthToken(apolloClient) {
  if (typeof localStorage !== "undefined") {
    localStorage.removeItem(AUTH_TOKEN);
  }
  if (apolloClient) {
    // if (apolloClient?.wsClient)
    //     restartWebsockets(apolloClient.wsClient)
    try {
      // await apolloClient.resetStore();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(
        "%cError on cache reset (logout)",
        "color: orange;",
        e.message
      );
    }
  }
}

export { apolloClient };
